.content {
    display: flex;

    .deliverables {
        flex: 1;
    }

    .delivered {
        align-items: center;
        color: #13be2a;
        display: flex;
        font-size: 1.125em;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        .text {
            font-weight: 600;
            margin-left: 0.5rem;
        }
    }
}
