.map {
    background-color: rgba(black, 0.25);
    margin-bottom: 1rem;
    margin-top: 1rem;
    min-height: 60vh;
    width: 100%;
}

.save {
    display: block;
    margin-left: auto;
}