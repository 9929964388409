@import '../../ui/mixins';

.card {
    margin-bottom: 1rem;

    .header {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.08);
        display: flex;
        padding: 0.625rem 1rem;
        width: auto;

        .title {
            flex: 1;
            font-size: 1.125rem;
            font-weight: 500;
            margin: 0 1rem;
            text-transform: uppercase;
        }
    }

    .orientations {
        h4 {
            font-size: 1rem;
            margin: 0;
        }

        .info {
            align-items: center;
            column-gap: 1rem;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto auto;

            .icon {
                color: $primary;
                grid-row: 1 / span 2;
                justify-self: center;
                margin-bottom: 0.5rem;
            }

            .title {
                grid-column: 2;
                grid-row: 1;
                margin: 0;
            }

            .text {
                grid-column: 2;
                grid-row: 2;
                margin: 0;
                white-space: pre-line;
            }
        }

        .form {
            align-items: center;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto auto;

            textarea {
                grid-column: 1 / span 2;
                grid-row: 2;
            }
        }
    }

    .rating {
        align-items: center;
        display: flex;

        h4 {
            font-size: 1rem;
            line-height: 1;
            margin: 0 1rem 0 0;
        }

        .rate {
            align-items: center;
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            justify-content: flex-end;

            .stars {
                color: $primary;
                margin-right: 0.75rem;
                min-width: 6rem;
            }

            .date {
                min-width: 9rem;
            }

            .description {
                margin-left: auto;
            }
        }
    }

    .processing {
        margin-bottom: 1rem;
        margin-top: 2rem;
        text-align: center;
        .icon {
            color: rgba(black, 0.4);
            font-size: 2rem;
        }
        .text {
            font-size: 0.875rem;
            margin-top: 0.5rem;
        }
    }

    .provider {
        align-items: center;
        display: flex;
        flex: 1;
        .name {
            margin-right: auto;
        }
        .input {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
        }
    }
}
