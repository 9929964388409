@import './shared/ui/mixins';

.navbar {
    background-color: black;
}

.wrapper {
    display: block;
    flex: 1;
    margin: 0 auto;
    transition: all 300ms ease-in-out;
    width: 100%;

    // @media only screen and (min-width: 540px) {
    // }

    @media only screen and (min-width: 720px) {
        max-width: 92%;
    }

    @media only screen and (min-width: 960px) {
        max-width: 94%;
    }

    @media only screen and (min-width: 1124px) {
        max-width: 96%;
    }
}

@include media-breakpoint-up(md) {

    .wrapper {

    }
}
