// Válido

.urls {
    column-gap: 1.25rem;
    display: flex;
    margin-bottom: 0.75rem;

    .wrapper {
        align-items: flex-end;
        column-gap: 0.75rem;
        display: flex;
        flex: 2;
        flex-direction: row;

        .link {
            flex: 1;
        }
    }

    .hostPrize {
        width: auto;
    }

    .delete {
        align-self: flex-end;
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 0.75rem;
}

.custom {
    border-radius: 6px;
    box-sizing: border-box;
    background: #f2f2f2;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    p {
        text-align: left;
    }

    .fields {
        display: flex;

        .info {
            column-gap: 1.25rem;
            display: grid;
            flex: 2;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
        }

        .logo {
            // @include card();

            margin-left: 1.25rem;

            .link {
                font-weight: normal;
                font-size: 1rem;
                line-height: 1.25rem;
                text-align: left;
                color: #0aa0d2;
                opacity: 1;

                &:hover {
                    cursor: pointer;
                    opacity: 0.6;
                }
            }
        }
    }

    .save {
        margin-top: 1rem;
    }
}
