@use 'sass:math';

.card {
    background: #fff;
    border-radius: 0.75rem;
    border: 1px solid #fff;
    box-sizing: border-box;
    padding: 1.25rem 1.5rem;
    margin: 0;
    width: 100%;

    &.big {
        min-height: 480px;
    }

    &.doubleBorderRadius {
        border-radius: 1.5rem;
    }

    &.shadow {
        box-shadow: 0 0 0.625rem rgba(127, 127, 127, 0.3);
    }

    &.solidBorder {
        border: 1px solid #ababab;
    }

    &.square {
        border-radius: 0;
    }

    @for $variant from 0 to 9 {
        &.variant-#{$variant}00 {
            $color: 255 - (255 * math.div($variant, 9));
            $variant_color: rgb($color, $color, $color);
            background-color: $variant_color;
            border: 1px solid $variant_color;

            @if $variant > 4 {
                color: white;
                border: 1px solid white;
            }
        }
    }

    &.variant-black {
        background-color: black;
        color: white;
    }

    &.variant-light-grey {
        background-color: rgb(244, 244, 244);
        border-color: rgb(244, 244, 244);
    }
}
