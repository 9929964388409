@import '../../ui/mixins';

.nav {
    display: flex;
    justify-content: center;
    padding: 0.75rem 0;
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 1020;

    .content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0 4%;
        position: relative;

        @include media-breakpoint-up(md) {
            .menu {
                flex: 1;
            }
        }
    }
}