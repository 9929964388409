.container {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.5rem;

    .dropdown {
        margin-left: 1rem;
        position: relative;
    }

    .toggle {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-right: 0.25rem;
        min-width: 12rem;
    }

    .input {
        max-width: 24rem;
    }

    .confirm {
        margin-left: 0.25rem;
        margin-right: 1rem;
    }

    .filters {
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
        padding: 0.25rem;

        .filter {
            align-items: center;
            border: 1px solid #cccccc;
            border-radius: 0.75rem;
            display: flex;
            font-size: 0.875rem;
            padding: 0 0.25rem;

            .remove {
                align-items: center;
                border-radius: 0.75rem;
                border-width: 0;
                display: flex;
                justify-content: center;
                margin-left: 0.25rem;
                margin-right: -0.25rem;
                height: 1.5rem;
                width: 1.5rem;
            }
        }
    }
}
