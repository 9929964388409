.container {
    display: flex;
    position: relative;

    .info {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        margin-right: 2rem;
        position: sticky;
        top: 0.25rem;
        width: 19rem;

        h1 {
            font-size: 1.25rem;
        }
        h2 {
            font-size: 1rem;
        }
        hr {
            width: 100%;
        }
        p {
            color: #7f7f7f;
            font-size: 0.75rem;
            margin-bottom: 0;
        }

        .address,
        .corporateName {
            margin-top: 0.25rem;
        }
    }

    .sections {
        display: flex;
        flex: 1;
        // padding: 20px 25px;
        // border-radius: 10px;
        // background: #fff;
        margin: 0;
        // box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
        box-sizing: border-box;
        flex-direction: column;
        width: 100%;
        flex: 1;
        overflow: hidden;

        .nav {
            margin-bottom: 0.625rem;

            .link {
                border-bottom: none;
                bottom: -1px;
                color: #000;
                display: inline-block;
                font-size: 1rem;
                font-weight: 400;
                line-height: 20px;
                padding: 0.375rem 0.75rem;
                position: relative;
                text-align: center;

                &.active {
                    background: #fff;
                    border-bottom: 3px solid #ffd600;
                }
            }
        }
    }
}
