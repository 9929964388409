.modal {
    .content {
        overflow: visible;
        .body {
            padding-bottom: 1rem;
            overflow-y: visible;
        }

        .grid {
            display: grid;
            gap: 0.5rem 1.75rem;
            grid-template-columns: 2fr 3fr 1fr;

            .label {
                align-self: center;
                font-size: 0.75rem;
                margin: 0;
            }
        }
    }
}
