@import '../../ui/mixins';

.button {
    
    border-width: 0;
    position: relative;
    transform: translateY(-0.3125rem);

    &, &::before, &::after {
        background-color: white;
        height: 0.1875rem;
        width: 2rem;
        transition: all 300ms ease-in-out;
        will-change: transform;
    }

    &::before, &::after {
        content: '';
        position: absolute;
    }

    &::before {
        left: 0;
        top: -0.625rem;
    }

    &::after {
        left: 0;
        top: 0.625rem;
    }

    &.opened {
        transform: rotate(-45deg);

        &::before {
            transform: rotate(-90deg) translateX(-0.625rem);
        }

        &::after {
            opacity: 0;
            transform: rotate(90deg);
        }
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}