@import '../../../shared/ui/mixins';

.container {
    position: relative;

    .column {
        display: flex;
        flex-direction: column;
        flex: 4;
        max-width: 100vw;
    }

    .sections {
        display: flex;
        margin: 0 0 15px;
        box-sizing: border-box;
        flex-direction: column;
        max-width: 100vw;
        min-height: 840px;
        width: 100%;

        .nav {
            margin-bottom: 0.625rem;

            .link {
                border-bottom: none;
                bottom: -1px;
                color: #000;
                display: inline-block;
                font-size: 1rem;
                font-weight: 400;
                line-height: 20px;
                padding: 0.375rem 0.75rem;
                position: relative;
                text-align: center;

                &.active {
                    background: #fff;
                    border-bottom: 3px solid #ffd600;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;

        .info {
            position: sticky;
        }
    }
}
