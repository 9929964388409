.content {
    display: flex;

    .deliverables {
        flex: 1;

        .tour {
            align-items: center;
            column-gap: 1rem;
            display: flex;

            .link {
                flex: 1;
            }

            .orientation {
                width: auto;
            }

            .delete {
                align-self: flex-end;
                margin-bottom: 0.75rem;
            }
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 0.75rem;
        }
    }
}
