.body {
    padding-bottom: 1rem;
}

.grid {
    display: grid;
    gap: 0.5rem 1.75rem;
    grid-template-columns: 2fr 1fr;

    .checklistLink {
        grid-row: 2;
    }

    .deliveryLink {
        grid-row: 3;
    }

    .responsibles {
        display: flex;
        gap: 1rem;
        grid-row: 4;
        margin-top: 1rem;

        .responsible {
            display: flex;
            flex: 1;
            flex-direction: column;
            gap: 0.25rem;
        }
    }

    .additional {
        grid-row: 5;
    }

    .scheduledFor {
        grid-column: 2;
        grid-row: 1;
    }

    .status {
        grid-column: 2;
        grid-row: 2;
    }

    .provider {
        grid-column: 2;
        grid-row: 3;
    }

    .vertical {
        align-items: flex-start;
        display: grid;
        gap: 0.5rem 1rem;
        grid-column: 2;
        grid-row: 4 / span 2;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto 1fr;

        .keys,
        .providerInfo {
            grid-column: 1 / span 2;
        }

        .providerInfo {
            align-self: stretch;
            display: flex;
            flex-direction: column;

            textarea {
                flex: 1;
            }
        }
    }
}
