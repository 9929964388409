.content {
    flex: 1;
    margin-right: 1rem;
    width: 18rem;

    .info {
        align-self: flex-start;
        flex: 1;
        margin-bottom: 1rem;
        text-align: center;
        

        h1 {
            font-size: 1.25rem;
        }
        h2 {
            font-size: 1rem;
            font-weight: normal;
        }
        hr {
            width: 100%;
        }
        p {
            color: #7f7f7f;
            font-size: 0.75rem;
            margin-bottom: 0;
        }

        .icon {
            border-color: rgba(black, 0.8);
            border-radius: 2.5rem;
            border-style: solid;
            border-width: 0.125rem;
            margin: 1rem auto;
            height: 5rem;
            width: 5rem;

            img {
                background-color: #ccc;
                border-radius: 5rem;
                height: 5rem;
                width: 5rem;
            }

            svg {
                font-size: 2rem;
                margin-top: 1.25rem;
            }
        }

        .address,
        .corporateName {
            margin-top: 0.25rem;
        }
    }

    .button {
        display: block;
        margin-left: auto;
        margin-top: 1rem;
    }
}
