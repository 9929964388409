@import '../../ui/mixins';

.container {
    margin-top: 2 * $modal-dialog-margin;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: 150ms linear;
    transition-delay: 150ms;
    transition-property: background-color;
    z-index: 1050;

    @include media-breakpoint-up(sm) {
        margin-top: 2 * $modal-dialog-margin-y-sm-up;
    }

    &.opened {
        background-color: rgba(black, 0.5);
        margin-top: 0;
        pointer-events: all;
        transition-delay: 0ms;
        & > div {
            pointer-events: all;
            transform: translateY(0);
        }
    }

    & > div {
        pointer-events: none;
        transform: translateY(100%);
        transition: all 300ms linear;
        transition-delay: 150ms;
        will-change: transform;
    }
}
