.container {
    display: flex;

    > div:first-child {
        margin-right: 0.25em;
    }
}

.input {
    padding-left: 2.25em;
    padding-right: 2em;
}

.calendar,
.clear {
    align-items: center;
    background-color: transparent;
    border: none;
    color: rgba(black, 0.7);
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.calendar {
    left: 0;
    padding-left: 0.75em;
    padding-right: 0.75em;
    pointer-events: none;
}

.clear {
    opacity: 0;
    padding-left: 0.75em;
    padding-right: 0.75em;
    pointer-events: none;
    right: 0;
    transition: all 200ms;

    &.clearable {
        opacity: 1;
        pointer-events: all;
    }
}
