.row {
    display: flex;
}

.hover:hover {
    background-color: #ededed;
}

.strip:nth-child(2n + 1) {
    background-color: #f4f4f4;
    &.hover:hover {
        background-color: #ededed;
    }
}

// .dense {
//     &.strip:nth-child(2n + 1) {
//         background-color: #e6e6e6;
//         &.hover:hover {
//             background-color: #dee2e6;
//         }
//     }

//     &.hover:hover {
//         background-color: #dee2e6;
//     }
// }
