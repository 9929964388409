@import '../../../ui/mixins';

.stars {
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
    button {
        background-color: transparent;
        border-width: 0;
        color: rgba(black, 0.7);
        height: 2rem;
        position: relative;
        width: 2rem;

        .filled, .regular {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        .filled {
            color: $primary;
            opacity: 0;
            z-index: 2;
        }

        &.checked .filled {
            opacity: 1;
        }
    }

    &:hover {
        .filled {
            opacity: 1;
        }

        button:hover ~ button .filled {
            opacity: 0;
        }
    }
}