.title {
    font-size: 0.875rem;
    margin: 0;
    text-align: center;
}

.container {
    position: relative;

    .show {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 1;

        .price {
            flex: 1;
            font-weight: 600;
            margin: 0;
            text-align: center;
        }

        .button {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }

    .show,
    .edit {
        align-items: center;
        display: flex;
        transition: opacity 200ms linear;

        .button {
            margin-left: 1rem;
        }
    }

    .edit {
        opacity: 0;
        pointer-events: none;

        .cancel {
            margin-right: 1rem;
        }
    }

    &.editing {
        .show {
            opacity: 0;
            pointer-events: none;
        }
        .edit {
            opacity: 1;
            pointer-events: all;
        }
    }
}
