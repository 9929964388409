.container {
    height: 100%;
    overflow-x: scroll;
    max-width: 100vw;
    /* temp fix */
    @media only screen and (min-width: 720px) {
        margin-left: -4%;
        margin-right: -4%;
    }

    @media only screen and (min-width: 960px) {
        margin-left: -3%;
        margin-right: -3%;
    }

    @media only screen and (min-width: 1124px) {
        margin-left: -2%;
        margin-right: -2%;
    }
}

.row {
    width: fit-content;
}

.id {
    min-width: 10rem !important;

    .button {
        align-items: center;
        display: flex;
        font-size: 1rem;
        padding: 0.25rem 0.5rem;

        span {
            font-size: 1.125rem;
            font-weight: 800;
            margin-right: 0.25rem;
        }
    }

    .status {
        margin-bottom: 0.125rem;
        margin-top: 0.125rem;
    }

    .date {
        font-size: 0.875rem;
    }
}

.client {
    min-width: 16rem !important;
}

.dates {
    min-width: 14rem !important;
}

.area {
    min-width: 10rem !important;
}

.host {
    min-width: 8rem !important;

    .months {
        margin-top: 0.25rem;
    }
}

.products {
    min-width: 12rem !important;
}

.price {
    min-width: 16rem !important;

    .grid {
        align-items: center;
        display: grid;
        gap: 0.125rem 0.25rem;
        grid-auto-flow: row;
        grid-template-columns: auto 7rem;
        text-align: left;
    }
}

.deliverables {
    min-width: 36rem !important;
    .grid {
        align-items: center;
        display: grid;
        gap: 0.125rem 0.25rem;
        grid-auto-flow: row;
        grid-template-columns: 12rem 17rem 7rem;
        text-align: left;
    }
}

.costs {
    min-width: 16rem !important;
    .grid {
        align-items: center;
        display: grid;
        gap: 0.125rem 0.25rem;
        grid-auto-flow: row;
        grid-template-columns: auto 7rem;
        text-align: left;
    }
}

.sla {
    min-width: 4rem !important;
}

.address {
    min-width: 18rem !important;
}

.month {
    min-width: 10rem !important;
    text-transform: capitalize;
}
