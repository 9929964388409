.content {
    display: flex;

    .deliverables {
        flex: 1;

        form {
            align-items: center;
            column-gap: 1rem;
            display: flex;

            .link {
                flex: 1;
            }

            .button {
                align-self: flex-end;
            }
        }
    }

    .products {
        margin-top: 1rem;

        .product {
            align-items: center;
            border-radius: 1rem;
            cursor: default;
            display: flex;
            margin-top: 0.125rem;
            margin-bottom: 0.125rem;
            padding: 0.5rem 0.75rem;

            &:hover {
                background-color: rgba(black, 0.05);
            }

            .icon {
                margin-right: 1rem;
            }

            .url {
                flex: 1;

                .createdAt {
                    color: rgba(black, 0.3);
                }
            }

            .copy,
            .remove {
                background-color: rgba(black, 0.13);
                border: none;
                border-radius: 0.9rem;
                cursor: pointer;
                height: 1.8rem;
                width: 1.8rem;
                margin-left: 0.5rem;

                &:hover {
                    background-color: rgba(black, 0.6);
                    color: white;
                }
            }
        }
    }
}
