.card {
    margin-bottom: 1rem;

    .providerService {
        padding-top: 2rem;
        position: relative;
        text-align: center;

        h6 {
            margin-top: 0.5rem;
        }

        .change {
            position: absolute;
            right: 1rem;
            top: 1rem;
        }

        .edit {
            position: absolute;
            left: 1rem;
            top: 1rem;
        }

        .edit {
            position: absolute;
            right: 1rem;
            bottom: 1rem;
        }

        .serviceName {
            font-size: 0.875rem;
        }

        .providers {
            display: grid;
            font-size: 0.875rem;
            grid-template-columns: 3fr 1fr;
            row-gap: 0.25rem;
            text-align: left;

            .label {
                grid-column: 1 / span 3;
                font-weight: 600;
            }

            .cost {
                text-align: right;
            }
        }
    }
}
