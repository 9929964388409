.input {
    display: flex;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .wrapper {
        flex: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    input {
        background: #fff;
        border: 1px solid #7f7f7f;
        border-radius: 6px;
        box-sizing: border-box;
        height: 38px;
        padding: 8px 10px;
        width: 100%;
    }
}

/**
$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #216ba5 !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%)
  !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: "Helvetica Neue", Helvetica, Arial,
  sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;
*/
