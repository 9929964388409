@import '../../ui/mixins';

.navbox {
    align-items: center;
    background-color: black;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    transform: translateY(-200%);
    transition: transform 300ms ease-in-out;
    width: 100%;
    z-index: -1;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        position: static;
        transform: translateY(0);
    }

    &.opened {
        transform: translateY(0);
    }
}