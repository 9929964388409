.autocomplete {
    position: relative;
}

.backdrop {
    background-color: rgba(black, 0.03);
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }
}

.input {
    position: relative;
    z-index: 1;
}

.clear {
    background: transparent;
    border: none;
    color: rgba(black, 0.8);
    cursor: pointer;
    opacity: 0;
    padding-left: 0.25rem;
    padding-right: 0.875em;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    transition: all 200ms;
    z-index: 2;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }
}

.menu {
    box-shadow: 0 0 0.625em rgba(127, 127, 127, 0.3);
    left: 0;
    right: 0;
    margin-top: 0.25em;
    max-height: 16em;
    overflow-y: auto;
}

.empty {
    cursor: default;
}

.loader {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    margin-top: 1em;
}
