.column {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0.875rem;
}

.dense {
    font-size: 0.875rem;
    padding: 0.375rem 0.25rem;
}

.clickable {
    cursor: pointer;
}
