.container {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr;

    .button {
        align-self: flex-end;
        grid-column: 4;
        justify-self: right;
    }
}
