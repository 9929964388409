.footer {
    align-items: center;
    display: flex;
    margin-bottom: 2rem;
    margin-top: 2rem;

    .button {
        margin-right: 2.5%;
    }
}
