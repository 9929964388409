.body {
    .service {
        background-color: white !important;
        padding: 0 !important;
        :global {
            ul {
                grid-template-columns: repeat(6, 1fr) !important;
            }
        }
    }
}

.warning {
    color: orange;
    font-weight: 600;
    margin-right: auto;
}
