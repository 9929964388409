.date {
    font-size: 0.875rem;
}

.address {
    font-size: 0.75rem;
}

.link a {
    display: block;
    font-size: 0.75rem;
    line-height: 1.25rem;
    padding-top: 0.5rem;
}

.detach {
    margin-top: 0.25rem;

    &:hover {
        background-color: rgba(black, 0.1);
    }
}
