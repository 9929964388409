.button {
    border-radius: 50%;
    border-width: 0;
    cursor: pointer;
    flex-shrink: 0;
    font-weight: 700;

    transition: all 200ms;

    &.active {
        background-color: #ffd600;
    }
}

.size-md {
    height: 2.75rem;
    width: 2.75rem;
}

.size-sm {
    height: 2rem;
    width: 2rem;
}

.variant-default {
    background-color: #f4f4f4;

    &:hover {
        background-color: rgba(black, 0.25);
    }
}

.variant-text {
    background-color: transparent;

    &:hover {
        background-color: rgba(black, 0.15);
    }
}
