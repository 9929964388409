@import './shared/ui/mixins';

.root {
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    
        .wrapper {
    
        }
    }
}