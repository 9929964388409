.margin-top {
    margin-top: 1rem;
}

.margin-bottom {
    margin-bottom: 1rem;
}

.shadow {
    box-shadow: 0 0 0.625rem rgba(127, 127, 127, 0.3);
}
