.body {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr auto 1fr;

    .title {
        margin-top: 0.75rem;
    }

    .product {
        align-items: center;
        display: flex;
        padding-bottom: 0.25rem;
        margin-bottom: 0.25rem;
        .icon {
            margin-right: 1rem;
            max-width: 2rem;
        }
        .item {
            margin: 0;
        }
    }

    .arrow {
        margin-top: 2rem;
    }
}

.error {
    color: red;
    font-weight: 600;
    margin-right: auto;
}
